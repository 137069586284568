import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

const Profile = (props) => {
    const { user, isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading ...</div>;
    }

    return (
        isAuthenticated && (
        <Box sx={{ flexGrow: 0 }}>
            <Avatar alt={user.name} src={user.picture} />
        </Box>
    )
    );
};

export default Profile;