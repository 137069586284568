import React from "react";

const SupportButton = (props) => {

    return (
    <button className="support-button bmc-btn-container" onClick={() => window.open('http://buymeacoffee.com/thehoodlums','_blank')}>
        <span className="support-button-text bmc-btn-text">Support this tool</span>
    </button>
    );
};

export default SupportButton;
