import './App.css';
import Form from './Form';
import NavBar from './NavBar';

function App() {
  return (
    <div className="App">
        <NavBar className='App-header'/>
      <Form/>
    </div>
  );
}

export default App;
