import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import Profile from './Profile';
import { useAuth0 } from '@auth0/auth0-react';
import SupportButton from './SupportButton';

const  NavBar = () => {
    const { isAuthenticated } = useAuth0(); 
    return (
    <Box sx={{ flexGrow: 1 }} className="nav">
        <AppBar className="nav" position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                <img alt="Three Skulls with baseball bats crossed behind them text intelligent hoodlums when in doubt trust a hoodlum" src="https://thelighthouse.sfo2.digitaloceanspaces.com/Graphics/logo.png"/>
                    {/* <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    >
                    <MenuIcon />
                    </IconButton> */}
                    {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Tabs
                    </Typography> */}
                    {isAuthenticated ? <Profile/> : <div></div>}
                    {isAuthenticated ? <LogoutButton class="pretty-button clearfix"></LogoutButton> : <LoginButton class="pretty-button nav-right"></LoginButton>}
                    <SupportButton></SupportButton>
                </Toolbar>
            </Container>
        </AppBar>
    </Box>
    );
}

export default NavBar;