import React, { useState } from "react";
import { Button, Fab, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, RadioGroup, Snackbar, Select, Stack, TextField, FormHelperText, Box, Radio, FormControl } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ChromePicker } from "react-color";

const toggles = {
    responsive: false
}

const defaultValues = {
    url: "https://www.theintelligenthoodlums.com",
    title: "The Intelligent Hoodlums",
    width: 800,
    height: 500,
    widthUnits: "px",
    heightUnits: "px",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#410100",
    padding: 0,
    margin: 0,
    scrolling: 'no',
    responsive: 'no',
    align: "center"
};

const Form = () => {
    const [showSnackbar, setShowSnackbar] = useState(false)
    const [showBorderControls, setShowBorderControls] = useState(false)

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSnackbar(false);
        };
    const openSnackbar = () => {
        setShowSnackbar(true)
    }

    const handleShowBorderControls = (event) => {
        console.log(`showing ${showBorderControls}`)
        if (event.target.value === "yes") {
            setShowBorderControls(true)
            setFormValues({
                ...formValues,
                hasBorder: 'yes'
            });
        } else {
            setShowBorderControls(false)
            setFormValues({
                ...formValues,
                hasBorder: 'no'
            });
        }
    }

    const [formValues, setFormValues] = useState(defaultValues);

    const handleInputChange = (e) => {
    console.log(`Handling change for ${JSON.stringify(formValues)} Border control: ${JSON.stringify(showBorderControls)}`)
        const { name, value } = e.target;
        console.log(`Setting values for ${name} as ${value}`)
        setFormValues({
            ...formValues,
            [name]: value
        });
    };
    const renderOutput = () => {
        let styling = `
        title="${formValues.title}"
        src="${formValues.url}"
        width="${formValues.width}${formValues.widthUnits}"
        height="${formValues.height}${formValues.heightUnits}"
        align="${formValues.align}"
        scrolling="${formValues.scrolling}"`
        let border = ""
        if (showBorderControls === true) {
            border = addBorderStyling()
        }
        let final = `<iframe ${styling} ${border}></iframe>`
        setFormValues({...formValues,
        output: final});
    }

    const addBorderStyling = () => {
        return ` style="border: ${formValues.borderWidth} ${formValues.borderStyle} ${formValues.borderColor}"`
    }

    const [borderColor, setBorderColor] = useState("#00325a")
    
    const handleChangeComplete = (color) => {
        setBorderColor(color.hex);
        setFormValues({
            ...formValues,
            borderColor: color.hex
        });

    };

    const handleLengthUnitChange = (event) => {
        console.log(`handling change of ${event.target.name} with value of ${event.target.value}`)
        const { name, value } = event.target;
        console.log(`${name} ${value}`)
        if (name === "widthUnits" && value === "%") {
            console.log(`${name} === widthUnits`)
            setFormValues({
                ...formValues,
                width: 100,
                widthUnits: "%"
            });
        }
        else if (name === "heightUnits" && value === "%") {
            console.log("inside height")
            setFormValues({
                ...formValues,
                height: 100,
                heightUnits: "%"
            });
        }
        else {
            console.log("Inside else")
            setFormValues({
                ...formValues,
                [name]: value
            });
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        renderOutput()
    };

    const BorderForm = () => {
            return (
                <Grid item sx={{margin: 5}}>
                    <Stack sx={{textAlign: "left"}}>
                        <FormControl>
                        <FormLabel>Border?</FormLabel>
                            <RadioGroup sx={{width: '33vw'}}
                                    aria-labelledby="want-border"
                                    defaultValue='no'
                                    value={formValues.hasBorder}
                                    id="want-border"
                                    name="wantBorder"
                                    onChange={handleShowBorderControls}>
                                    <FormControlLabel value="yes" control={<Radio/>} label="Yes"/>
                                    <FormControlLabel value="no" control={<Radio/>} label="No"/>
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                { showBorderControls ?
                        <Stack direction="row" sx={{margin: 5}}>
                            <Grid item sx={{margin: 5}}>
                                <TextField
                            id="border-width"
                            name="borderWidth"
                            label="Border Width"
                            type="number"
                            value={formValues.borderWidth}
                            onChange={handleInputChange} />
                            </Grid>
                            <Grid item sx={{margin: 5}}>
                            <Select
                                id="border-style"
                                name="borderStyle"
                                value={formValues.borderStyle}
                                label="Style"
                                onChange={handleInputChange}>
                                <MenuItem value={"hidden"}>hidden</MenuItem>
                                <MenuItem value={"dotted"}>dotted</MenuItem>
                                <MenuItem value={"dashed"}>dashed</MenuItem>
                                <MenuItem value={"solid"}>solid</MenuItem>
                                <MenuItem value={"groove"}>groove</MenuItem>
                                <MenuItem value={"ridge"}>ridge</MenuItem>
                                <MenuItem value={"inset"}>inset</MenuItem>
                                <MenuItem value={"outset"}>outset</MenuItem>
                            </Select>
                            </Grid>
                            <Grid item sx={{margin: 5}}>
                                <ChromePicker
                                    color={borderColor}
                                    onChangeComplete={handleChangeComplete} />
                            </Grid>
                            </Stack>
                :  <Grid item></Grid>
            }
            </Grid>
        )
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3} sx={{margin: 5, padding:2}}>
                <Stack>
                    <Stack direction="row" sx={{margin:5}}>
                    <Grid item>
                            <TextField
                            id="url-input"
                            name="url"
                            label="URL"
                            type="text"
                            value={formValues.url}
                            onChange={handleInputChange}
                            sx={{width: '33vw'}}
                            />
                    </Grid>
                    <Grid item>
                    <TextField
                        id="title-input"
                        name="title"
                        label="Title"
                        type="text"
                        value={formValues.title}
                        onChange={handleInputChange}
                        sx={{width: '33vw'}}
                    />
                    </Grid>
                    </Stack>
                    <Stack direction="row" sx={{margin:5}}>
                    <Grid item>
                        <TextField
                        id="width-input"
                        name="width"
                        label="Width"
                        type="number"
                        value={formValues.width}
                        onChange={handleInputChange}
                        />
                        <Select labelId="width-units-label"
                            id="width-units"
                            name="widthUnits"
                            value={formValues.widthUnits}
                            label="Width Units"
                            onChange={handleLengthUnitChange}>
                                <MenuItem value={"px"}>px</MenuItem>
                                <MenuItem value={"%"}>%</MenuItem>
                                <MenuItem value={"em"}>em</MenuItem>
                                <MenuItem value={"vw"}>vw</MenuItem>
                            </Select>
                    </Grid>
                    </Stack>
                    <Stack direction="row" sx={{margin:5}}>
                    <Grid item>
                        <TextField
                        id="height-input"
                        name="height"
                        label="Height"
                        type="number"
                        value={formValues.height}
                        onChange={handleInputChange}
                        />
                        <Select labelId="height-units-label"
                            id="height-units"
                            name="heightUnits"
                            value={formValues.heightUnits}
                            label="Height Units"
                            onChange={handleLengthUnitChange}>
                                <MenuItem value={"px"}>px</MenuItem>
                                <MenuItem value={"%"}>%</MenuItem>
                                <MenuItem value={"em"}>em</MenuItem>
                                <MenuItem value={"vw"}>vh</MenuItem>
                        </Select>
                    </Grid>
                    </Stack>
                    <Stack direction="row" sx={{margin:5}}>
                    <Grid item>
                        <TextField
                            id="margin-input"
                            name="margin"
                            label="Margin"
                            type="number"
                            value={formValues.margin}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="padding-input"
                            name="padding"
                            label="Padding"
                            type="number"
                            value={formValues.padding}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    </Stack>
                <Stack direction="row" sx={{margin:5}}>
                    <Grid item>
                        <InputLabel id="align-label">Align</InputLabel>
                        <Select labelId="align-label"
                                id="align"
                            name="align"
                            value={formValues.align}
                            label="Align"
                            onChange={handleInputChange}
                            sx={{width: '33vw'}}>
                                <MenuItem value={"left"}>Left</MenuItem>
                                <MenuItem value={"center"}>Center</MenuItem>
                                <MenuItem value={"right"}>Right</MenuItem>
                            </Select>
                    </Grid>
                    <Grid item>
                        <InputLabel id="scrolling-label">Scrolling</InputLabel>
                        <Select labelId="scrolling-label"
                            id="scrolling"
                            name="scrolling"
                            value={formValues.scrolling}
                            label="Scrolling"
                            sx={{width: '33vw'}}
                            onChange={handleInputChange}>
                                <MenuItem value={"yes"}>yes</MenuItem>
                                <MenuItem value={"no"}>no</MenuItem>
                        </Select>
                    </Grid>
                    { toggles.responsive ?
                    <Grid item>
                        <InputLabel id="responsive-label">Responsive</InputLabel>
                        <Select labelId="responsive-label"
                            id="responsive"
                            name="responsive"
                            value={formValues.responsive}
                            label="Responsive"
                            sx={{width: '20vw', color:"black"}}
                            onChange={handleInputChange}>
                                <MenuItem value={"yes"}>yes</MenuItem>
                                <MenuItem value={"no"}>no</MenuItem>
                        </Select>
                        <FormHelperText>Choose yes if you want your embed to adjust to multiple screen sizes.</FormHelperText>
                    </Grid>
                    : <Grid item></Grid>}
                </Stack>
                <Grid item>
                    <BorderForm/>
                </Grid>
                <Stack direction="row" sx={{margin:5}}>
                <Grid item>
                    <Fab variant="extended" color="primary" type="submit" sx={{margin: 1}}>
                        Submit
                    </Fab>
                </Grid>
                </Stack>
                <Grid item>
                    <Box sx={{margin: 5, padding: 5, backgroundColor: '#CAC4D0', minHeight: '30vw', border: "3px solid #ffdad2"}}>
                                {formValues.output}
                    </Box>
                    <Box>
                            <CopyToClipboard text={formValues.output} onCopy={openSnackbar}>
                                <Button variant="contained" size="medium">Copy</Button>
                            </CopyToClipboard>
                    </Box>
                </Grid>
                    <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleClose} message="Copied!"></Snackbar>
                </Stack>
            </Grid>
        </form>
    );
};

export default Form;